/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './syncmodal.css';
import loadergif from '../assets/loader.gif';
import userPlaceholder from "../assets/placeholder.jpg";
import checkForSocioMee from 'src/actions/checkForSocioMee';
import socioMeeAuth from 'src/actions/socioAuth';
import { useToasts } from 'react-toast-notifications';
import syncSociomates from 'src/actions/syncSociomates';

function SyncModal({ isOpen, closeModal, children }) {

  const { addToast } = useToasts();
  const [loader, setLoaderVisible] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isMigrated, setIsLogisetIsMigrated] = useState(false);
  const [socioMeeProfile, setSocioMeeProfile] = useState({});
  const [lastMigrated, setLastMigrated] = useState(null);
  const [isSocioMeeProfileAvalable, setSocioMeeProfileAvaibility] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const checkForsocioMeeUser = async () => {
      setLoaderVisible(true);
      const response = await checkForSocioMee("token");
      if (response.status === 200) {
        if (response.data.status) {
          if (response.data.data.errorResult) {
            console.log(response.data.data.errorResult);
            setSocioMeeProfileAvaibility(false);
          }
          else {
            setSocioMeeProfile(response.data.data.successResult);
            setLastMigrated(response.data.isMigratedOn);
            setSocioMeeProfileAvaibility(true);
          }
        }
        console.log(response.data);
      }

      setLoaderVisible(false);
    }
    checkForsocioMeeUser();
  }, []);

  const addSociomates = async(token , pageIndex , pageSize=25)=>{
    await syncSociomates({ token , pageIndex:0 , pageSize}).then( async (responce)=>{
      if(responce.status === 200) {
        console.log(responce.data.next);
       try {
        if(responce.data.next){
          await addSociomates(token ,1 ,10);
        }else{
          setLoaderVisible(false);
          return;
        }
       } catch (error) {
        setLoaderVisible(false);
        return;
       }
      }
    });
   
  }

  const fetchData = async (pageNumber = 0) => {
    try {
      const token = socioMeeProfile.authToken;
      const pageSize =10;
      const response = await syncSociomates({ token , pageIndex:pageNumber , pageSize});
      const result = response.data;

      // Check if the response has a "next" field set to true
      if (result.next) {
        await fetchData(pageNumber + 1);
      } else {
        setLoaderVisible(false);
        setIsLogisetIsMigrated(true);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoaderVisible(false);
    }
  };



  const handleSyncClick = async () => {
      setLoaderVisible(true);
      fetchData();
  }
  const handleLogin = async() => {
    if(username === ''){
      addToast(`Username required!`, {
        appearance: 'error',
        autoDismiss: true,
      });
       return;
    }
    if(password === ''){
      addToast(`Please enter password first!`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }
    
    const response = await socioMeeAuth({loginId : username , password});
    if(response.status===200){
      console.log(response.data);
       if(response.data.error){
        addToast(response.data.errorDesc, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
       }else{

        setSocioMeeProfile(response.data.data.successResult);
        setSocioMeeProfileAvaibility(true);
        addToast(`LoggedIn Successfully!!`, {
          appearance: 'success',
          autoDismiss: true,
        });
       }
      
    }else{
      addToast(`Something went wrong!`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };
  const getProfileImage = (profileImage)=>{
    if (profileImage.includes("https://")) {
      return profileImage;
    } else {
      return "https://sociomee-dev.s3.ap-south-1.amazonaws.com/"+profileImage;
    }
  }
  const getMigratedDateTime = (originalDateString)=>{
    const date = new Date(originalDateString);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
  
    // Format the date using the specified options
    const formattedDate = date.toLocaleString('en-IN', options);

    return formattedDate;
  }
  return (
    <div id="">
      <div className="uk-container msg-sync-modal-container">
        {/* First row: Loader GIF in center */}
        {loader && <div className="uk-flex uk-flex-center uk-margin-bottom">
          <img src={loadergif} alt="Loader" className='sync-loader' />
        </div>}


        {isSocioMeeProfileAvalable && <>
          <div className="uk-flex uk-flex-start uk-margin-bottom uk-margin-top" style={{ minWidth: '300px' }}>
            <div className="uk-text-center uk-flex uk-flex-row">
              <img src={ getProfileImage(socioMeeProfile.profileImage) ?? userPlaceholder} alt="User" className="uk-border-circle userimage" />
              <div className="uk-flex uk-flex-column uk-margin-left" style={{ marginLeft: '10px', marginTop: '10px' }}>
                <p className="uk-margin-remove-top" style={{ marginBottom: '5px' }}>{socioMeeProfile.fullName}</p>
                <p className="uk-margin-remove-top">{"@"+socioMeeProfile.userName}</p>
              </div>
            </div>
            
          </div>
          
          { isMigrated && <p className='uk-text-start uk-text-emphasis uk-flex uk-flex-row remove-uk-margin-top msgmee_migrated_text '>Syncing succesfully completed!!</p>}
          { (lastMigrated && !isMigrated) && <p className='uk-text-start uk-text-emphasis uk-flex uk-flex-row remove-uk-margin-top magmee_migrated_datetime'>Last migrated on {getMigratedDateTime(lastMigrated.createdAt)}</p>}
          <div className="uk-flex uk-flex-center uk-margin-bottom">
            <button className="uk-button uk-button-default msg-close-button" onClick={closeModal}>Close</button>
           { !isMigrated && <button className="uk-button uk-button-primary msg-primary-button uk-margin-left" onClick={handleSyncClick}>Sync</button>}
          </div>
        </>}

        {!isSocioMeeProfileAvalable && <>
          { !isLogin ? <><div className="uk-flex uk-flex-start uk-margin-bottom uk-margin-top" style={{ minWidth: '300px' }}>
            <div className="uk-text-center uk-flex uk-flex-row">
              <h3>No user sociomee account found.</h3>
            </div>
          </div>

            <div className="uk-flex uk-flex-column uk-flex-center uk-margin-bottom">
              <button className="uk-button uk-button-primary msg-primary-button uk-margin-bottom" onClick={()=>{setIsLogin(true);}}>Login with creds</button>
              <button className="uk-button uk-button-default msg-close-button" onClick={closeModal}>Close</button>

            </div></> : <div className="uk-flex uk-flex-start uk-margin-bottom uk-margin-top">
            <div className="uk-flex uk-flex-column uk-margin-left" style={{ minWidth: '300px' }}>
 
              <h3 className="uk-text-center uk-flex">Login with creds.</h3>
 
              <input type="text" placeholder="Username"  onChange={(e) => setUsername(e.target.value)} className="uk-input uk-margin-small-bottom" />
              <input type="password" placeholder="Password"  onChange={(e) => setPassword(e.target.value)} className="uk-input uk-margin-small-bottom" />
              <div className='uk-flex uk-flex-center uk-margin-bottom uk-margin-top'>
              <button className="uk-button uk-button-primary msg-primary-button" onClick={handleLogin}>Login</button>
              <button className="uk-button uk-button-default msg-close-button uk-margin-left" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>}

        </>}


      </div>
    </div>
  );
}

export default SyncModal;
