import { useGlobal } from 'reactn';
import './NavBar.sass';
import {
  FiMessageCircle, FiStar, FiSearch, FiPhoneCall,
} from 'react-icons/fi';

function NavBar() {
  const [nav, setNav] = useGlobal('nav');

  return (
    <div className="nav-bar uk-flex">
      <div className={`item${nav === 'rooms' ? ' active' : ''}`} onClick={() => setNav('rooms')}>
        <div className="icon">
          <FiMessageCircle />
        </div>
        <div className="text">Chats</div>
      </div>
      <div className={`item${nav === 'search' ? ' active' : ''}`} onClick={() => setNav('search')}>
        <div className="icon">
          <FiSearch />
        </div>
        <div className="text">Contacts</div>
      </div>
      <div className={`item${nav === 'favorites' ? ' active' : ''}`} onClick={() => setNav('favorites')}>
        <div className="icon">
          <FiStar />
        </div>
        <div className="text">Favorites</div>
      </div>
      <div
        className={`item${nav === 'meetings' ? ' active' : ''}`}
        onClick={() => {
          setNav('meetings');
        }}
      >
        <div className="icon">
          <FiPhoneCall />
        </div>
        <div className="text">Calls</div>
      </div>
    </div>
  );
}

export default NavBar;
